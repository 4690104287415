import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpGeneralService } from './http-general.service';
import { APIConstants } from '../models/api-constants';
import { APIResponseGet, APIResponsePost } from '../models/api-response/api-response';
import { ApplicationInsightsService } from './application-insights.service';
import { Configuration } from '../models/api-request/configuration-request';
import { ImportFileRequest } from '../models/api-request/import-file-request';
import { RetryFilesRequest } from '../models/api-request/retry-files-request';
import { YearWeek } from '../models/view-model/year-week';
import { ExtractFileResponse } from '../models/api-response/extract-file-response';
import { ConfigurationResponse } from '../models/api-response/configuration-response';
import { CheckHealthResponse } from '../models/api-response/check-health-response';
import { ImportFileResponse } from '../models/api-response/import-file-response';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private httpGeneralService: HttpGeneralService,private applicationInsightsService:ApplicationInsightsService) {
    }

    getYearWeekList(): Observable<APIResponseGet<YearWeek[]>> {
        this.applicationInsightsService.logTrace('Method Get api/batchchecker/yearweeks is called.');
        return this.httpGeneralService.get<YearWeek[]>(APIConstants.yearWeeks);
    }

    getImportFileList(yearWeek?: string): Observable<APIResponseGet<ExtractFileResponse[]>> {
        this.applicationInsightsService.logTrace('Method Get api/batchchecker/extract is called.',{'param': yearWeek});
        return this.httpGeneralService.get<ExtractFileResponse[]>(yearWeek != undefined && yearWeek != null ? APIConstants.extract+'?yearWeek=' + yearWeek : APIConstants.extract);
    }

    getConfiguration(): Observable<APIResponseGet<ConfigurationResponse>> {
        this.applicationInsightsService.logTrace('Method Get api/configuration is called.');
        return this.httpGeneralService.get<ConfigurationResponse>(APIConstants.configuration);
    }

    saveConfiguration(requestModel: Configuration): Observable<APIResponsePost> {
        this.applicationInsightsService.logTrace('Method Post api/configuration is called.',{'param':requestModel});
        return this.httpGeneralService.post<Configuration>(APIConstants.configuration, requestModel);
    }

    checkHealth(): Observable<APIResponseGet<CheckHealthResponse>> {
        this.applicationInsightsService.logTrace('Method Get api/configuration/checkhealth is called.');
        return this.httpGeneralService.get<CheckHealthResponse>(APIConstants.checkHealth);
    }

    import(requestModel: ImportFileRequest): Observable<APIResponseGet<ImportFileResponse>> {
        this.applicationInsightsService.logTrace('Method Post api/batchchecker/import is called.',{'param':requestModel});
        return this.httpGeneralService.postData<ImportFileRequest, ImportFileResponse>(APIConstants.import, requestModel, 300000);
    }

    retryfiles(requestModel: RetryFilesRequest): Observable<APIResponsePost> {
        this.applicationInsightsService.logTrace('Method Post api/batchchecker/retryfiles is called.',{'param':requestModel});
        return this.httpGeneralService.post(APIConstants.retryfiles, requestModel);
    }

}
